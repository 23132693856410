<template>
	<div>
		<pui-datatable
			v-if="masterDetail"
			:modelName="modelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:actions="actions"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
	</div>
</template>

<script>
import securityformsActions from './SecurityformsActions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'securityforms-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'securityforms',
			pkAttribute: 'securityformid',
			actions: securityformsActions.actions,
			actionsMasterDetail: securityformsActions.actionsMasterDetail,
			modelColumnDefs: {}
		};
	},
	methods: {}
};
</script>
